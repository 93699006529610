import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  BarController,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  BarController,
  Title,
  Tooltip,
  Legend
);
const DashChart = ({ dashboardList, selectedMonth }) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthLength = dashboardList?.daywise_totalApplication?.length || 30;
  const labels = Array.from({ length: monthLength }, (_, i) => i + 1);
  const dataPoints = [0.0, 30.0, 60.0, 90.0, 120.0];
  const filledDataPoints = Array(30)
    .fill(0)
    .map((_, i) => dataPoints[i] || 0);

  const data = {
    labels: labels,
    datasets: [
      {
        type: "bar",
        label: "Total Application",
        data: dashboardList?.daywise_totalApplication,
        backgroundColor: "#12468a",
        borderColor: "#12468a",
        barThickness: 6,
        borderWidth: 0.5,
      },
      {
        type: "bar",
        label: "Disbursed Application",
        data: dashboardList?.daywise_disbursed_application,
        backgroundColor: "#ffbe0b",
        borderColor: "#ffbe0b",
        barThickness: 6,
        borderWidth: 0.5,
      },
      // {
      //   type: "bar",
      //   label: "Rejected Application",
      //   data: dashboardList?.daywise_step2Application,
      //   backgroundColor: "#f06548",
      //   borderColor: "#f06548",
      //   barThickness: 6,
      //   borderWidth: 0.5,
      // },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Status Distribution",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div
      style={{
        marginTop: "20px",
        display: "grid",
        background: "#fff",
        padding: "15px",
      }}
    >
      <div style={{ textAlign: "center" }}>
        {/* <p></p> */}
        <div style={{ display: "flex", gap: "5px", justifyContent: "left" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "#12468a",
              textTransform: "uppercase",
            }}
          >
            {selectedMonth} 2024
          </p>
        </div>
      </div>
      <div style={{ display: "relative", width: "100%" }}>
        <Chart type="bar" data={data} options={options} />
      </div>
    </div>
  );
};

export default DashChart;
