import React, { useState } from "react";
import styles from "./Table.module.css";
import { FaSort } from "react-icons/fa";
import {
  MdDelete,
  MdOutlineAddCircleOutline,
  MdPlaylistAddCheckCircle,
} from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import { useNavigate } from "react-router-dom";
import AddCharges from "./AddCharges";
import ViewCharges from "./ViewCharges";
const OEMProducts = ({ rowData, page, numberOfRecords, perm, type }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [char, setChar] = useState(false);
  const addCharHandle = () => {
    setChar((val) => !val);
  };
  const handleCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setChar((val) => !val);
  };
  const [viewChar, setViewChar] = useState(false);
  const ViewCharHandle = () => {
    setViewChar((val) => !val);
  };
  const handleViewCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setViewChar((val) => !val);
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const navigate = useNavigate();
  const handleEditRow = (val) => {
    navigate("/add-product", {
      state: { val },
    });
  };
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      <div className={styles.tabwrap}>
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                background: "linear-gradient(to bottom, #12468a, #235392)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th style={{ minWidth: "40px" }}>#</th>
                <th>Dealer</th>

                <th style={{ minWidth: "120px" }}>Product Type</th>
                <th style={{ minWidth: "120px" }}>Model Name</th>

                <th style={{ minWidth: "120px" }}>Battery Type</th>

                <th style={{ minWidth: "120px" }}>Ex-showroom Price</th>
                <th style={{ minWidth: "120px" }}>Loan to Value Ratio</th>
                <th style={{ minWidth: "120px" }}>Min Loan Amount</th>
                <th style={{ minWidth: "120px" }}>Max Loan Amount</th>
                <th style={{ minWidth: "120px" }}>Min Loan Tenure</th>
                <th style={{ minWidth: "120px" }}>Max Loan Tenure</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td>{row.dealer_name}</td>
                  <td>{row.product_type}</td>
                  <td>{row.name}</td>

                  <td>{row.battery_type}</td>

                  <td>{row.ex_showroom_price}</td>
                  <td>{row.loan_to_value_ratio}</td>
                  <td>{row.min_loan_amount}</td>
                  <td>{row.max_loan_amount}</td>
                  <td>{row.min_loan_tenure}</td>
                  <td>{row.max_loan_tenure}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
      </div>
    </div>
  );
};

export default OEMProducts;
