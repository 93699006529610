import React, { useState } from "react";
import styles from "./Table.module.css";
import { FaEye, FaSort, FaUserClock } from "react-icons/fa";
import {
  MdDelete,
  MdElectricRickshaw,
  MdOutlineAddCircleOutline,
  MdPlaylistAddCheckCircle,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
const Table = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [char, setChar] = useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [viewChar, setViewChar] = useState(false);
  const ViewCharHandle = () => {
    setViewChar((val) => !val);
  };
  const handleViewCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setViewChar((val) => !val);
  };
  const addCharHandle = () => {
    setChar((val) => !val);
  };
  const handleCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setChar((val) => !val);
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  console.log("rowData", rowData);
  const navigate = useNavigate();
  const handleView = (val) => {
    console.log("val", val);
    navigate("/oem-product-list", {
      state: { val },
    });
  };
  const handleLoan = (val) => {
    console.log("val", val);
    navigate("/oem-borrower-list", {
      state: { val },
    });
  };

  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      {" "}
      <div className={styles.tabwrap}>
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                background: "linear-gradient(to bottom, #12468a, #235392)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th>#</th>
                <th>Dealers Name</th>
                <th> Dealer ID</th>
                <th> Email</th>
                <th> Mobile</th>
                {/* {perm?.includes("update") && (
                  <th style={{ maxWidth: "90px" }}> Status</th>
                )} */}

                <th> Action</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr key={row.id}>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td>{row.name}</td>
                  <td>{row.dealer_unq}</td>
                  <td>{row.email}</td>
                  <td>{row.mobile}</td>

                  <td>
                    {" "}
                    <button
                      title="Products"
                      type="button"
                      style={{
                        background: "#45cb85",
                      }}
                      onClick={() => handleView(row)}
                      className={styles.editbtn}
                    >
                      <MdElectricRickshaw
                        size={12}
                        style={{
                          position: "relative",
                          top: "1px",
                        }}
                      />
                    </button>
                    <button
                      type="button"
                      title="View Loan Details"
                      style={{
                        background: "orange",
                      }}
                      onClick={() => handleLoan(row)}
                      className={styles.editbtn}
                    >
                      <FaUserClock
                        size={12}
                        style={{
                          position: "relative",
                          top: "1px",
                        }}
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
      </div>
    </div>
  );
};

export default Table;
