import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import classes from "./MultiSelectInput.module.css";

const MultiSelectInput = ({
  options,
  getOptionLabel,
  onChange,
  value,
  renderOption,
  getOptionSelected,
  isOptionEqualToValue,
  className,
}) => {
  const [formattedValue, setFormattedValue] = useState([]);
  // console.log("muivalue", value);
  useEffect(() => {
    if (value !== formattedValue && value !== undefined) {
      setFormattedValue(value);
    }
  }, [value, formattedValue]);
  // useEffect(() => {
  //   if (
  //     Array.isArray(value) &&
  //     JSON.stringify(value) !== JSON.stringify(formattedValue)
  //   ) {
  //     setFormattedValue(value);
  //   } else if (!Array.isArray(value)) {
  //     setFormattedValue([]);
  //   }
  // }, [value, formattedValue]);

  // console.log(formattedValue);
  // const filteredOptions = options.filter(
  //   (option) => !formattedValue.some((selected) => selected.id === option.id)
  // );

  return (
    <div style={{ width: "700px", height: "100%" }}>
      <Autocomplete
        style={{ height: "100%" }}
        disablePortal
        multiple
        id="tags-outlined"
        options={options}
        className={className ? className : ""}
        getOptionLabel={getOptionLabel}
        // getOptionSelected={getOptionSelected}
        renderOption={renderOption}
        filterSelectedOptions
        onChange={onChange}
        classes={{ paper: classes.customAutocompletePaper }}
        value={formattedValue}
        {...(isOptionEqualToValue ? { isOptionEqualToValue } : {})}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            // sx={{
            //   "& .MuiOutlinedInput-root": {
            //     padding: "unset",
            //     // height: "100%",
            //   },
            //   "& .MuiOutlinedInput-notchedOutline": {
            //     borderColor: "#dadada !important",
            //     borderWidth: "1px !important",
            //   },
            //   "& .MuiAutocomplete-input": {
            //     padding: "unset !important",
            //     // height: "100%",
            //   },
            //   "& .MuiOutlinedInput-input": {
            //     boxSizing: "border-box",
            //     padding: "unset",
            //     fontSize: "11px",
            //   },
            //   "& .MuiChip-root": {
            //     fontSize: "11px",
            //     height: "25px",
            //   },
            //   "& .MuiChip-deleteIcon": {
            //     fontSize: "15px",
            //   },

            //   "& .MuiAutocomplete-tag": {
            //     margin: "1px",
            //   },
            //   // "& .MuiTextField-root": {
            //   //   height: "100%",
            //   // },
            //   height: "100%",
            // }}
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                paddingRight: "15px",
              },

              "& .MuiOutlinedInput-root": {
                padding: "unset",
                height: "100%",
                paddingRight: "5px !important",
                paddingLeft: "5px !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#dadada !important",
                borderWidth: "1px !important",
              },
              "& .MuiAutocomplete-input": {
                padding: "unset !important",
                // height: "100%",
              },
              "& .MuiOutlinedInput-input": {
                boxSizing: "border-box",
                padding: "unset",
                fontSize: "11px",
                height: "max-content",
              },
              "& .MuiChip-root": {
                fontSize: "11px",
                height: "25px",
              },
              "& .MuiChip-deleteIcon": {
                fontSize: "15px",
              },
              "& .MuiAutocomplete-clearIndicator": {
                fontSize: "10px",
                color: "white",
              },
              "& .MuiAutocomplete-clearIndicator:hover": {
                background: "rgb(105, 105, 105)",
              },
              "& .MuiAutocomplete-endAdornment": {
                right: "3px",
                // top: "calc(50% - 12px)",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "15px",
              },
              height: "100%",
            }}
          />
        )}
      />
    </div>
  );
};

export default MultiSelectInput;
