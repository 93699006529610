import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { useLocation, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import TableOEM from "./TableOEM";
import PaginationComponent from "../../Common/PaginationComponent";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../Common/api";
import Loader from "../../Common/Loader";
import SearchOEM from "./SearchOEM";
import { AuthContext } from "../../../context/AuthContext";
const OEMBorrwer = () => {
  const [page, setPage] = useState(1);
  const [numberOfRecords, setNumberOfRecords] = useState(10);
  const [step, setStep] = useState(null);
  const [product, setProduct] = useState(null);
  const [status, setStatus] = useState(null);
  const [month, setMonth] = useState(null);
  const [perm, setPerm] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const ShowSearchHandler = (event, value) => {
    setShowSearch((val) => !val);
  };
  const { activeSubmenuId } = useContext(AuthContext);
  const [add, setAdd] = useState(false);
  const [totalPage, setTotalPage] = useState(2);
  const [filterD, setFilterD] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const location = useLocation();
  const { val } = location.state || "";
  console.log("val in bor", val);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const fetchData = async ({
    page,
    numberOfRecords,
    searchData,
    step,
    month,
    status,
    typ,
  }) => {
    const payload = {
      page,
      number_of_records: numberOfRecords,
      step: step,
      month: month,
      status: status,
      product_type_id: product,
    };

    if (val?.id) {
      payload.dealer_id = val?.id || "";
    }
    if (searchData) {
      payload.start_date = searchData?.start_date || "";
      payload.end_date = searchData?.end_date || "";
      payload.borrower_name = searchData?.borrower_name || "";
      payload.borrower_id = searchData?.borrower_id || "";
      payload.mobile_no = searchData?.mobile_no || "";
      payload.pan_no = searchData?.pan_no || "";
      payload.aadhar_no = searchData?.aadhar_no || "";
      payload.dob = searchData?.dob || "";
      payload.application_date = searchData?.application_date || "";
      payload.dealer_id = searchData?.dealer_id || "";
      payload.lsp_id = searchData?.lsp_id || "";
    }

    const response = await api.get("admin/get-oem-borrower-list", {
      // const response = await api.get(`admin/get-oem-borrower-list/${val.id}`, {
      params: payload,
    });
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: [
      "borrowerList",
      page,
      numberOfRecords,
      searchData,
      step,
      month,
      status,

      product,
    ],
    queryFn: () =>
      fetchData({
        page,
        numberOfRecords,
        searchData,
        step,
        month,
        status,

        product,
      }),
    onSuccess: (data) => {
      console.log("on succ data", data);
      setTotalPage(data.data?.last_page);
    },
  });

  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;

  const navigate = useNavigate();
  const addHandle = () => {
    navigate("/add-borrower");
  };

  const searchDataHandler = (data) => {
    setSearchData(data);
    setPage(1);
  };
  const handleRecordsChange = (event) => {
    setNumberOfRecords(event.target.value);
  };
  const handleStep = (event) => {
    setStep(event.target.value);
  };
  const handleProduct = (event) => {
    setProduct(event.target.value);
  };

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleMonth = (event) => {
    console.log("event", event.target.value);
    setMonth(event.target.value);
  };
  console.log("activeSubmenuId", activeSubmenuId);
  const months = [
    { label: "Jan", value: "January" },
    { label: "Feb", value: "February" },
    { label: "Mar", value: "March" },
    { label: "Apr", value: "April" },
    { label: "May", value: "May" },
    { label: "Jun", value: "June" },
    { label: "Jul", value: "July" },
    { label: "Aug", value: "August" },
    { label: "Sep", value: "September" },
    { label: "Oct", value: "October" },
    { label: "Nov", value: "November" },
    { label: "Dec", value: "December" },
  ];

  const fetchPermissions = async () => {
    const response = await api.get(
      `admin/get-sub-menu-permissions/${activeSubmenuId}`
    );
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions", activeSubmenuId],
    queryFn: fetchPermissions,
    enabled: !!activeSubmenuId,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  const { data: permissionsData } = PermissionsResponse;

  const [{ data: loanStatus }, { data: ProductData }] = useQueries({
    queries: [
      {
        queryKey: ["loanStatus"],
        queryFn: () => api.get("get-loan-status-list").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["prodata"],
        queryFn: () =>
          api.get("admin/get-product-types").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      {perm?.includes("read") && (
        <div className={classes.mainBodyContainer}>
          <Breadcum pageName="Borrower List" />
          <div className={classes["main-body"]} style={{ padding: "15px" }}>
            <div className={classes.tablco}>
              {showSearch && (
                <SearchOEM
                  onSearch={searchDataHandler}
                  filterD={filterD}
                  setProduct={setProduct}
                  setMonth={setMonth}
                  setStatus={setStatus}
                  setStep={setStep}
                />
              )}

              <div className={classes.inputsearchcon}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <select
                    onChange={handleRecordsChange}
                    value={numberOfRecords}
                    className={classes.selectfotnu}
                    style={{ width: "55px" }}
                  >
                    {["10", "20", "50", "100"].map((value) => (
                      <option
                        key={value}
                        style={{ fontSize: "11px" }}
                        value={value}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                  {/* <select
                  style={{ width: "110px" }}
                  onChange={handleStep}
                  value={step ?? ""}
                  className={classes.selectfotnu}
                >
                  <option value="">Select Step</option>
                  <option value="1">Registration</option>
                  <option value="2">KYC</option>
                  <option value="3">Agreement Sign</option>
                  <option value="4">PDD</option>
                </select> */}
                  {/* <select
                  style={{ width: "115px" }}
                  onChange={handleMonth}
                  value={month ?? ""}
                  className={classes.selectfotnu}
                >
                  <option value="">Select Month</option>
                  {months.map((val, id) => (
                    <option value={val?.value}>{val?.label}</option>
                  ))}
                </select> */}
                  <select
                    style={{ width: "115px" }}
                    onChange={handleStatus}
                    value={status ?? ""}
                    className={classes.selectfotnu}
                  >
                    <option value="">Select Status</option>
                    {loanStatus?.data?.map((val, id) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>

                  {/* <select
                  style={{ width: "125px" }}
                  onChange={handleProduct}
                  value={product ?? ""}
                  className={classes.selectfotnu}
                >
                  <option value="">Select Product</option>
                  {ProductData?.data?.map((val, id) => (
                    <option value={val.id}>{val.name}</option>
                  ))}
                </select> */}
                </div>
                <div>
                  <button
                    type="button"
                    className={classes.gobtn}
                    onClick={ShowSearchHandler}
                  >
                    <FaSearch
                      className={classes.gobtnicon}
                      size={12}
                      style={{ borderRadius: "5px", padding: "5px" }}
                    />
                  </button>
                </div>
              </div>
              <div className={classes.tableContai}>
                {isLoading || isFetching ? (
                  <Loader />
                ) : (
                  <TableOEM
                    rowData={dashboardList?.data || []}
                    page={page}
                    numberOfRecords={numberOfRecords}
                    perm={perm}
                  />
                )}
              </div>
              <div className={classes.paginCont}>
                <p className={classes.pagitext}>
                  Showing {dashboardList?.data?.from} to{" "}
                  {dashboardList?.data?.to} of {dashboardList?.data?.total}{" "}
                  entries.
                </p>
                <PaginationComponent
                  pageChangeHandler={handleChange}
                  pageState={page}
                  totalPageNo={totalPage}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OEMBorrwer;
