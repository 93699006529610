import React, { useContext, useEffect } from "react";
import styles from "../EditDealerD/BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";

import { useQueries, useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
const DealerForm = ({ defaultValue, deforadd }) => {
  const { user, token, setUser, setToken, baseURL, baseDoc } =
    useContext(AuthContext);
  const [add, setAdd] = useState(false);
  const fetchData = async () => {
    const response = await api.get(
      `admin/edit-oem-product-specification/${defaultValue.id}`
    );
    return response.data;
  };
  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {
      setExistingData(data?.data);
    },
  });
  const [rowToEdit, setRowToEdit] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [existingData, setExistingData] = useState(null);
  const [err, setErr] = useState({});
  const [pdfUrl, setPdfUrl] = React.useState("");
  const [perm, setPerm] = useState([]);
  const navigate = useNavigate();
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  const fetchPermissions = async () => {
    const response = await api.get("admin/get-sub-menu-permissions/50");
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions"],
    queryFn: fetchPermissions,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  console.log("deforadd oem add", deforadd);
  const { data: permissionsData } = PermissionsResponse;
  const [{ data: depatmentList }] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("get-department-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });

  console.log("defaultValue", defaultValue);
  useEffect(() => {
    if (defaultValue) {
      setValue("motor", defaultValue?.motor);
      setValue("battery", defaultValue?.battery);
      setValue("controller", defaultValue?.controller);
      setValue("tyre", defaultValue?.tyre);
      setValue("performance", defaultValue?.performance);
    }
  }, [defaultValue, setValue]);
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  const handleViewPdf = (url) => {
    const fullUrl = `${baseDoc}${url}`;
    setPdfUrl(fullUrl);
    setOpen(true);
  };

  const onSubmit = async (data) => {
    console.log("Form data to submit:", data);
    const formData = new FormData();
    if (!deforadd.product_specification) {
      formData.append("oem_id", deforadd.id);
    }
    // if (!defaultValue && !defaultValue.id) {
    //   formData.append("oem_id", deforadd.id);
    // }

    formData.append("motor", data.motor);
    formData.append("battery", data.battery);
    formData.append("controller", data.controller);
    formData.append("tyre", data.tyre);
    formData.append("performance", data.performance);

    const fileFields = ["brochure"];
    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }
    try {
      const apiEndpoint = defaultValue
        ? `${baseURL}admin/update-oem-product-specification/${defaultValue.id}`
        : `${baseURL}admin/store-oem-product-specification`;
      // : `${baseURL}admin/store-oem-product-specification`;

      const method = defaultValue ? "post" : "post";

      const response = await axios({
        method: method,
        url: apiEndpoint,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data && response.data.status) {
        const { status, message } = response.data;
        showToast(message, status);
        navigate("/oem-list");
        console.log("Form submitted successfully:", response.data);
      } else {
        console.error("Unexpected response format:", response);
        showToast("Unexpected response format", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.data) {
        const { message, errors } = error.response.data;
        setErr(message);
        showToast(message, "error");
      } else {
        showToast("An error occurred while submitting the form", "error");
      }
    }
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  return (
    <div style={{ marginBottom: "15px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.searchformasc}>
          <div className={styles.totalcheckconnn}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Motor
              </label>
              <input
                {...register("motor")}
                type="text"
                className={styles.inputstst}
              />
              {errors.motor && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Battery
              </label>
              <input
                {...register("battery")}
                type="text"
                className={styles.inputstst}
              />
              {errors.battery && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Controller
              </label>
              <input
                {...register("controller")}
                type="text"
                className={styles.inputstst}
              />
              {errors.controller && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Tyre Size
              </label>
              <input
                {...register("tyre")}
                type="text"
                // step="0.01"
                className={styles.inputstst}
              />

              {errors.tyre && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Performance
              </label>
              <input
                {...register("performance")}
                // type="number"
                // step="0.01"
                className={styles.inputstst}
              />

              {errors.performance && (
                <span className={styles.error}>{"This Field is Missing."}</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Brochure
              </label>

              <input
                type="file"
                className={styles.uploadbx}
                {...register("brochure")}
                accept="image/jpeg, image/png,image/jpg,application/pdf"
              />
              {errors.nbfc_certificate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>

          {perm?.includes("create") && (
            <div className={styles.buttonasc}>
              {defaultValue && (
                <button type="submit" className={styles.Subbtn}>
                  Update
                </button>
              )}
            </div>
          )}
          <div className={styles.buttonasc}>
            {!defaultValue && (
              <button type="submit" className={styles.Subbtn}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default DealerForm;
