import React from "react";
import { useForm } from "react-hook-form";
import styles from "./Search.module.css";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
const SearchComp = ({ onSearch }) => {
  const { register, handleSubmit, reset, watch } = useForm();
  const onSubmit = (data) => {
    console.log("searchForm submitted:", data);
    onSearch(data);
  };
  const resetHandler = () => {
    reset();
    onSearch(null);
  };
  const [{ data: departmentList }] = useQueries({
    queries: [
      {
        queryKey: ["branchlist"],
        queryFn: () =>
          api.get("get-department-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.fourinputasc} style={{ gap: "20px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Name
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("name")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Email
            </label>
            <input
              type="email"
              className={styles.inpttsc}
              {...register("email")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Mobile
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("mobile")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Department
            </label>

            <select
              {...register("department")}
              className={styles.inpttsc}
              style={{ height: "29px" }}
            >
              <option value="">--Select Department--</option>
              {departmentList?.data?.map((row, i) => (
                <option key={i} value={row.id}>
                  {row.department}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button
            style={{
              maxHeight: "30px",
              border: "none",
              color: "#fff",
              background: "#45cb85",
              borderRadius: "5px",
              position: "relative",
              top: "14px",
              height: "25px",
            }}
            type="submit"
          >
            Search
          </button>
          <button
            style={{
              maxHeight: "30px",
              border: "none",
              color: "#fff",
              background: "#f06548",
              borderRadius: "5px",
              position: "relative",
              top: "14px",
              height: "25px",
            }}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComp;
