import React, { createContext, useState, useEffect, useContext } from "react";

export const AuthContext = createContext({
  user: null,
  token: null,
  activeSubmenuId: null,
  baseURL: "http://13.200.65.87/api/",
  baseDoc: "http://13.200.65.87/",
  ver: "v2/",
  // baseURL: "https://insta.finayo.tech/api/",
  // baseDoc: "https://insta.finayo.tech/",
  // ver: "v1/",
  setUser: () => {},
  setToken: () => {},
  setActiveSubmenuId: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [token, _setToken] = useState(localStorage.getItem("ACCESS_TOKEN"));
  const [activeSubmenuId, setActiveSubmenuId] = useState(null);

  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("ACCESS_TOKEN", token);
    } else {
      localStorage.removeItem("ACCESS_TOKEN");
      localStorage.removeItem("borrowerListFilters");
      localStorage.removeItem("borrowerListB2CFilters");
      localStorage.removeItem("borrowerListB2BFilters");
      localStorage.removeItem("DealerListFilters");
      localStorage.removeItem("ProductListFilters");
    }
  };
  useEffect(() => {
    const monitorToken = setInterval(() => {
      const storedToken = localStorage.getItem("ACCESS_TOKEN");

      if (!storedToken) {
        setToken(null);
        setUser({});
      }
    }, 1000);

    return () => clearInterval(monitorToken);
  }, [token]);
  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        setUser,
        baseURL: "http://13.200.65.87/api/",
        baseDoc: "http://13.200.65.87/",
        ver: "v2/",
        // baseURL: "https://insta.finayo.tech/api/",
        // baseDoc: "https://insta.finayo.tech/",
        // ver: "v1/",
        setToken,
        activeSubmenuId,
        setActiveSubmenuId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
