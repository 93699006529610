import React, { useState } from "react";
import styles from "./Table.module.css";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdDelete, MdDownloading } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import profl from "./../../../icons/profl.jpg";
import { IoDocuments } from "react-icons/io5";
import AddModal from "./AddModal";
import RemarkM from "./RemarkM";
const LenderTable = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [re, setRe] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  const reHandle = () => {
    setRe((val) => !val);
  };
  const ReqHandler = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  const HandleApprove = (id, type) => {
    setRowToEdit(id);
    // setType(type);

    setRe((val) => !val);
  };
  console.log("rowToEdit", rowToEdit);
  const navigate = useNavigate();
  const handleSignIn = (borrowerId) => {
    navigate("/view-lender-borrower-details", {
      state: { borrowerId, perm },
    });
  };
  const handleDocIn = (borrowerId) => {
    navigate("/edit-documents", {
      state: { borrowerId },
    });
  };
  console.log("rowData", rowData);
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      {" "}
      {rowToEdit && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AddModal addHandle={addHandle} defaultValue={rowToEdit || []} />
        </Backdrop>
      )}
      <div className={styles.tabwrap}>
        {" "}
        {rowData?.data && rowData?.data.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                background: "linear-gradient(to bottom, #12468a, #235392)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th style={{ minWidth: "40px" }}>#</th>
                <th
                  className={styles.tabforstic}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr auto",
                    alignItems: "center",

                    minWidth: "140px",
                    border: "1px solid #ddd",
                    borderTop: "1px solid #ddd",
                  }}
                >
                  Borrower Name
                </th>

                <th style={{ minWidth: "120px" }}>Borrower ID</th>
                <th style={{ minWidth: "120px" }}>Dealer ID</th>
                <th style={{ minWidth: "120px" }}>Step Status</th>
                <th style={{ minWidth: "120px" }}>Loan Status</th>
                <th style={{ minWidth: "120px" }}>Last Step</th>
                <th style={{ minWidth: "120px" }}>Product</th>
                <th style={{ minWidth: "120px" }}>Mobile No</th>
                <th style={{ minWidth: "120px" }}>Date Of Birth</th>
                <th style={{ minWidth: "120px" }}>Marital Status</th>
                <th style={{ minWidth: "120px" }}>Spouse's Name</th>
                <th style={{ minWidth: "120px" }}>Father's Name</th>
                <th style={{ minWidth: "120px" }}>Gender</th>
                <th style={{ minWidth: "120px" }}>Alternate Number</th>
                <th style={{ minWidth: "120px" }}>Email ID</th>
                <th style={{ minWidth: "120px" }}>PAN No</th>
                <th style={{ minWidth: "120px" }}>Aadhar No</th>
                <th style={{ minWidth: "120px" }}>Driving Licence No</th>
                <th style={{ minWidth: "120px" }}>State</th>
                <th style={{ minWidth: "120px" }}>Country</th>
                <th style={{ minWidth: "160px" }}>Borrower Credit Score</th>
                <th style={{ minWidth: "160px" }}>Co-Applicant Credit Score</th>
                <th style={{ minWidth: "160px" }}>Guarantor Credit Score</th>
                <th style={{ minWidth: "120px" }}>Postal</th>
                <th style={{ minWidth: "120px" }}>Loan Amount</th>
                <th style={{ minWidth: "120px" }}>Down Payment</th>
                <th style={{ minWidth: "120px" }}>Loan ID</th>
                <th style={{ minWidth: "120px" }}>Created On</th>
                <th style={{ minWidth: "140px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.data?.map((row, index) => (
                <tr
                  key={row?.id}
                  // style={{
                  //   backgroundColor:
                  //     row.loan_status === "REJECTED" ? "#ffa0a0" : "inherit",
                  // }}
                >
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      position: "sticky",
                      left: "0",
                      background: "#fff",
                    }}
                  >
                    {row.borrower_name && (
                      <img
                        src={profl}
                        style={{
                          borderRadius: "100%",
                          width: "30px",
                        }}
                      />
                    )}
                    <span
                      style={{
                        position: "relative",
                        bottom: "10px",
                        paddingLeft: "10px",
                      }}
                    >
                      {row.borrower_name}
                    </span>
                  </td>
                  <td>{row.borrower_id}</td>
                  <td>
                    <p
                      onClick={() => handleEditRow(row)}
                      className={styles.upohver}
                    >
                      {row.dealer_unq}
                    </p>
                  </td>
                  <td>
                    {row.step_status === "step1"
                      ? "Registration"
                      : row.step_status === "step2"
                      ? "KYC"
                      : row.step_status === "step3"
                      ? "Agreement Sign"
                      : row.step_status === "step4"
                      ? "PDD"
                      : ""}
                  </td>
                  <td>
                    <span
                      style={{
                        padding: "5px",
                        background:
                          row.loan_status === "REJECTED"
                            ? "#ffa0a0"
                            : "inherit",
                        color:
                          row.loan_status === "REJECTED" ? "#fff" : "inherit",
                        borderRadius: "4px",
                      }}
                    >
                      {row.loan_status}
                    </span>
                  </td>
                  <td>{row.last_step?.details}</td>
                  <td>{row.model}</td>
                  <td>{row.mobile_no}</td>
                  <td>{row.date_of_birth}</td>
                  <td>{row.marital_status}</td>
                  <td>{row.spouse_name}</td>
                  <td>{row.fathers_name}</td>
                  <td>{row.gender}</td>
                  <td>{row.alternate_number1}</td>
                  <td>{row.email_id}</td>
                  <td>{row.pan_no}</td>
                  <td>{row.aadhar_no}</td>
                  <td>{row.driving_license_no}</td>
                  <td>{row.address?.data?.full_address?.state}</td>
                  <td>{row.address?.data?.full_address?.country}</td>
                  <td>{row.borrower_cibil}</td>
                  <td>{row.coapplicant_cibil}</td>
                  <td>{row.guarantor_cibil}</td>
                  <td>{row.address?.data?.full_address?.pc}</td>
                  <td>{row.loan_amount}</td>
                  <td>{row.down_payment}</td>
                  <td>{row.loan_id}</td>
                  <td>{row.created_on}</td>
                  <td>
                    {" "}
                    {perm?.includes("update") && (
                      <button
                        type="button"
                        // onClick={() => handleEditRow(row)}
                        className={styles.editbtn}
                      >
                        <AiFillEdit
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                    <button
                      type="button"
                      className={styles.viewbtn}
                      onClick={() => handleSignIn(row?.borrower_id)}
                    >
                      <FaEye
                        size={12}
                        style={{ position: "relative", top: "1px" }}
                      />
                    </button>
                    {perm?.includes("delete") && (
                      <button type="button" className={styles.dltbtn}>
                        <MdDelete
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                    {perm?.includes("update") && (
                      <button
                        type="button"
                        className={styles.dltbtn}
                        style={{ marginLeft: "10px", background: "teal" }}
                        onClick={() => handleDocIn(row?.borrower_id)}
                      >
                        <IoDocuments
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                    <button
                      type="button"
                      className={styles.dltbtn}
                      title="Request Download Access"
                      onClick={() => HandleApprove(row)}
                    >
                      <MdDownloading
                        size={12}
                        style={{ position: "relative", top: "1px" }}
                      />
                      {/* Request Download Access */}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={re}
        onClick={reHandle}
      >
        <RemarkM
          addHandle={reHandle}
          defaultValue={rowToEdit || ""}
          // type={type || ""}
        />
      </Backdrop>
    </div>
  );
};

export default LenderTable;
