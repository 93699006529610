import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { FaSearch } from "react-icons/fa";
import PaginationComponent from "../../Common/PaginationComponent";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import Loader from "../../Common/Loader";
import SearchOEM from "./SearchOEM";
import OEMTable from "./OEMTable";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
const OEMOwn = () => {
  const [page, setPage] = useState(1);
  const [numberOfRecords, setNumberOfRecords] = useState(10);
  const [add, setAdd] = useState(false);
  const [filterD, setFilterD] = useState(null);
  const [totalPage, setTotalPage] = useState(2);
  const [searchData, setSearchData] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [perm, setPerm] = useState([]);
  const ShowSearchHandler = (event, value) => {
    setShowSearch((val) => !val);
  };
  const location = useLocation();
  const { val } = location.state || "";
  console.log("val in bor", val);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const addHandle = () => {
    setAdd((val) => !val);
  };
  const fetchData = async ({ page, numberOfRecords, searchData }) => {
    const payload = { page, number_of_records: numberOfRecords };

    if (searchData) {
      payload.start_date = searchData?.start_date || "";
      payload.end_date = searchData?.end_date || "";
      payload.max_loan_amount = searchData?.max_loan_amount || "";
      payload.min_loan_tenure = searchData?.min_loan_tenure || "";
      payload.max_loan_tenure = searchData?.max_loan_tenure || "";
      payload.product_type = searchData?.product_type || "";
      payload.dealer_id = searchData?.dealer_id || "";
      payload.model_unq = searchData?.model_unq || "";
      payload.name = searchData?.name || "";
      payload.battery_type = searchData?.battery_type || "";
      payload.ex_showroom_price = searchData?.ex_showroom_price || "";
      payload.loan_to_value_ratio = searchData?.loan_to_value_ratio || "";
      payload.manufacture_name = searchData?.manufacture_name || "";
      payload.interest = searchData?.interest || "";
    }

    const response = await api.get("admin/get-oem-own-products", {
      params: payload,
    });
    return response.data;
  };
  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList", page, numberOfRecords, searchData],
    queryFn: () => fetchData({ page, numberOfRecords, searchData }),
    onSuccess: (data) => {
      setTotalPage(data.last_page);
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  console.log("dashboardList", dashboardList);
  const handleRecordsChange = (event) => {
    setNumberOfRecords(event.target.value);
  };
  const searchDataHandler = (data) => {
    setSearchData(data);
    setPage(1);
  };
  const { activeSubmenuId } = useContext(AuthContext);
  const fetchPermissions = async () => {
    const response = await api.get(
      `admin/get-sub-menu-permissions/${activeSubmenuId}`
    );
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions", activeSubmenuId],
    queryFn: fetchPermissions,
    enabled: !!activeSubmenuId,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      {perm?.includes("read") && (
        <div className={classes.mainBodyContainer}>
          <Breadcum pageName="OEM Own Products " />
          <div className={classes["main-body"]} style={{ padding: "15px" }}>
            <div className={classes.tablco}>
              {/* {showSearch && (
                <SearchOEM onSearch={searchDataHandler} filterD={filterD} />
              )} */}
              <div className={classes.inputsearchcon}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <select
                    onChange={handleRecordsChange}
                    value={numberOfRecords}
                    className={classes.selectfotnu}
                  >
                    {["10", "20", "50", "100"].map((value) => (
                      <option
                        key={value}
                        style={{ fontSize: "11px" }}
                        value={value}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  {/* <button
                    type="button"
                    className={classes.gobtn}
                    onClick={ShowSearchHandler}
                  >
                    <FaSearch
                      className={classes.gobtnicon}
                      size={12}
                      style={{ borderRadius: "5px", padding: "5px" }}
                    />
                  </button> */}
                </div>
              </div>
              <div className={classes.tableContai}>
                {isLoading || isFetching ? (
                  <Loader />
                ) : (
                  <OEMTable
                    rowData={dashboardList?.data || []}
                    page={page}
                    numberOfRecords={numberOfRecords}
                    perm={perm}
                    type="OEM"
                  />

                  // <OEMProducts
                  //   rowData={dashboardList?.data || []}
                  //   page={page}
                  //   numberOfRecords={numberOfRecords}
                  //   perm={perm}
                  //   type="OEM"
                  // />
                )}
              </div>
              {/* <div className={classes.paginCont}>
              <p className={classes.pagitext}>
                {" "}
                Showing {dashboardList?.from} to {dashboardList?.to} of{" "}
                {dashboardList?.total} entries.
              </p>
              <PaginationComponent
                pageChangeHandler={handleChange}
                pageState={page}
                totalPageNo={totalPage}
              />
            </div> */}
            </div>
          </div>
        </div>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <AddModal addHandle={addHandle} />
      </Backdrop>
    </div>
  );
};

export default OEMOwn;
