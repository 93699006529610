import React, { useContext, useState } from "react";
import classes from "../ProductModalAsset/Language.module.css";
import Breadcum from "../../Common/Breadcum";
import Table from "./Table";

import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import api from "../../Common/api";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { Controller, useForm } from "react-hook-form";
import ListWithSearch from "../../Common/ListWithSearch/ListWithSearch";
const UserJouney = () => {
  const [page, setPage] = useState(1);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    control,
    setValue,
  } = useForm();
  const [numberOfRecords, setNumberOfRecords] = useState(10);
  const [add, setAdd] = useState(false);
  const [totalPage, setTotalPage] = useState(2);
  const [searchData, setSearchData] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [perm, setPerm] = useState([]);
  const [isSearchTriggered, setIsSearchTriggered] = useState(false);
  const { activeSubmenuId } = useContext(AuthContext);
  const lenderId = watch("lender_id");
  const [{ data: depatmentList }] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("admin/get-all-borrower-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  const fetchData = async ({ page, numberOfRecords, searchTerm }) => {
    const response = await api.get(
      `admin/get-api-details-by-borrower/${searchTerm}`
    );
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList", page, numberOfRecords, searchTerm],
    queryFn: () => fetchData({ page, numberOfRecords, searchTerm }),
    onSuccess: (data) => {
      setTotalPage(data.last_page);
    },
    enabled: isSearchTriggered && searchTerm !== "",
  });

  const { data: dashboardList } = DetailsResponse;

  const handleSearchInputChange = (event) => {
    setSearchData(event.target.value);
  };

  // const handleSearch = () => {
  //   setSearchTerm(searchData);
  //   setIsSearchTriggered(true);
  // };
  const handleSearch = () => {
    if (lenderId) {
      setSearchTerm(lenderId); // Set searchTerm with lender_id value
      setIsSearchTriggered(true);
    }
  };

  const fetchPermissions = async () => {
    const response = await api.get(
      `admin/get-sub-menu-permissions/${activeSubmenuId}`
    );
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions", activeSubmenuId],
    queryFn: fetchPermissions,
    enabled: !!activeSubmenuId,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      {perm?.includes("read") && (
        <div className={classes.mainBodyContainer}>
          <Breadcum pageName="User API Log" />
          <div className={classes["main-body"]} style={{ padding: "15px" }}>
            <div className={classes.tablco}>
              <div className={classes.inputsearchcon}>
                <div style={{ display: "flex", gap: "10px", width: "300px" }}>
                  <div
                    style={{ textAlign: "left" }}
                    className={classes.searorinpzrssw}
                  >
                    <p style={{ fontSize: "12px" }}>Borrower ID</p>

                    <Controller
                      control={control}
                      rules={{ required: "This Field is Missing" }}
                      render={({ field: { onChange, value } }) => (
                        <ListWithSearch
                          options={depatmentList?.data || []}
                          getOptionLabel={(val) => val.name || ""}
                          renderOption={(props, option) => (
                            <li
                              {...props}
                              key={option.id}
                            >{`${option.name} (${option.borrower_id})`}</li>
                          )}
                          className={classes.listSrch}
                          onChange={(event, selectedValue) => {
                            onChange(selectedValue ? selectedValue?.id : null);
                          }}
                          value={
                            depatmentList?.data?.find(
                              (employee) => employee.id === value
                            ) || null
                          }
                        />
                      )}
                      name={`lender_id`}
                    />
                  </div>

                  <button
                    type="button"
                    style={{ height: "29px", marginTop: "13px" }}
                    className={classes.addbtn}
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className={classes.tableContai}>
                <Table
                  rowData={dashboardList?.data || []}
                  page={page}
                  numberOfRecords={numberOfRecords}
                  perm={perm}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserJouney;
