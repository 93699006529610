import React, { useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineNavigateNext } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import Table from "./Table";
import PaginationComponent from "../../Common/PaginationComponent";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../Common/api";
import Loader from "../../Common/Loader";
import SearchComp from "./SearchComp";
import { useLocation } from "react-router-dom";
import ViewDet from "./ViewDet";

const LenderAuthority = () => {
  const [page, setPage] = useState(1);
  const [add, setAdd] = useState(false);
  const [perm, setPerm] = useState([]);
  const [numberOfRecords, setNumberOfRecords] = useState(10);
  const [totalPage, setTotalPage] = useState(2);
  const [searchData, setSearchData] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const location = useLocation();
  const { val } = location.state || {};
  console.log("val", val);
  const ShowSearchHandler = (event, value) => {
    setShowSearch((val) => !val);
  };
  const searchDataHandler = (data) => {
    setSearchData(data);
    setPage(1);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  const addHandle = () => {
    setAdd((val) => !val);
  };
  const fetchData = async ({ page, numberOfRecords, searchData, val }) => {
    const payload = { lender_id: val?.id };

    const response = await api.get("admin/get-lender-directors", {
      params: payload,
    });
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: ["userlist", page, numberOfRecords, searchData, val],
    queryFn: () => fetchData({ page, numberOfRecords, searchData, val }),
    onSuccess: (data) => {
      console.log("on succ data", data);
      setTotalPage(data.data?.last_page);
    },
    refetchOnWindowFocus: false,
  });

  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  console.log("dashboardList", dashboardList);
  const handleRecordsChange = (event) => {
    setNumberOfRecords(event.target.value);
  };
  console.log("searcd", searchData);
  const fetchPermissions = async () => {
    const response = await api.get("admin/get-sub-menu-permissions/50");
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions"],
    queryFn: fetchPermissions,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  const { data: permissionsData } = PermissionsResponse;
  console.log("perm", perm);
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      {perm?.includes("read") && (
        <div className={classes.mainBodyContainer}>
          <Breadcum pageName="Lender Details" />
          <div className={classes["main-body"]} style={{ padding: "15px" }}>
            <div className={classes.tablco}>
              {/* {showSearch && <SearchComp onSearch={searchDataHandler} />} */}
              <ViewDet defaultValue={val} />
              <div className={classes.inputsearchcon}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <p
                    style={{
                      fontSize: "15px",
                      color: "#12468a",
                      fontWeight: "bold",
                      position: "relative",
                      top: "5px",
                    }}
                  >
                    LENDER AUTHORITY
                  </p>
                  {/* <select
                  onChange={handleRecordsChange}
                  value={numberOfRecords}
                  className={classes.selectfotnu}
                >
                  {["10", "20", "50", "100"].map((value) => (
                    <option
                      key={value}
                      style={{ fontSize: "11px" }}
                      value={value}
                    >
                      {value}
                    </option>
                  ))}
                </select> */}
                  {perm?.includes("create") && (
                    <button
                      type="button"
                      className={classes.addbtn}
                      onClick={addHandle}
                    >
                      <IoMdAdd
                        size={11}
                        style={{
                          paddingRight: "5px",
                          position: "relative",
                          top: "1px",
                        }}
                      />
                      Add
                    </button>
                  )}
                </div>
                <div>
                  {/* <button
                  type="button"
                  className={classes.gobtn}
                  onClick={ShowSearchHandler}
                >
                  <FaSearch
                    className={classes.gobtnicon}
                    size={12}
                    style={{ borderRadius: "5px", padding: "5px" }}
                  />
                </button> */}
                </div>
              </div>
              <div className={classes.tableContai}>
                {isLoading || isFetching ? (
                  <Loader />
                ) : (
                  <Table
                    rowData={dashboardList?.data || []}
                    page={page}
                    numberOfRecords={numberOfRecords}
                    perm={perm}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <AddModal addHandle={addHandle} val={val} />
      </Backdrop>
    </div>
  );
};

export default LenderAuthority;
