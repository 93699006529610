import React, { useContext, useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineNavigateNext } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import Table from "./Table";
import PaginationComponent from "../../Common/PaginationComponent";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../Common/api";
import Loader from "../../Common/Loader";
import SearchComp from "./SearchComp";

import { Navigate, useNavigate } from "react-router-dom";
import ExcelForm from "./ExcelForm";
import { AuthContext } from "../../../context/AuthContext";
const ExcelDisbursment = () => {
  const { activeSubmenuId } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [upd, setUpd] = useState(0);
  const [numberOfRecords, setNumberOfRecords] = useState(10);
  const [add, setAdd] = useState(false);
  const [perm, setPerm] = useState([]);
  const [totalPage, setTotalPage] = useState(2);
  const [searchData, setSearchData] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  console.log("upd", upd);
  const ShowSearchHandler = (event, value) => {
    setShowSearch((val) => !val);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const searchDataHandler = (data) => {
    setSearchData(data);
    setPage(1);
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };

  const fetchData = async ({ page, numberOfRecords, searchData }) => {
    const payload = { page, number_of_records: numberOfRecords };

    if (searchData) {
      payload.bankId = searchData.bankId || "";
    }

    const response = await api.get("admin/get-uploaded-disbursement-files", {
      params: payload,
    });
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: ["uploadedExcel", page, numberOfRecords, searchData, upd],
    queryFn: () => fetchData({ page, numberOfRecords, searchData, upd }),
    onSuccess: (data) => {
      setTotalPage(data?.data?.last_page);
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  console.log("dashboardList", dashboardList);
  const handleRecordsChange = (event) => {
    setNumberOfRecords(event.target.value);
  };

  const fetchPermissions = async () => {
    const response = await api.get(
      `admin/get-sub-menu-permissions/${activeSubmenuId}`
    );
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions", activeSubmenuId],
    queryFn: fetchPermissions,
    enabled: !!activeSubmenuId,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  const { data: permissionsData } = PermissionsResponse;
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      {perm?.includes("read") && (
        <div className={classes.mainBodyContainer}>
          <Breadcum pageName="Excel Uploaded Disbursement List" />
          <div className={classes["main-body"]} style={{ padding: "15px" }}>
            <div className={classes.tablco}>
              {perm?.includes("import") && (
                <div style={{ padding: "10px 0" }}>
                  {" "}
                  <p
                    style={{
                      fontSize: "11px",
                      textAlign: "left",
                      fontWeight: "bold",
                      color: "#495057",
                      paddingBottom: "3px",
                    }}
                  >
                    Upload Bulk Disbursement
                  </p>
                  <ExcelForm setUpd={setUpd} upd={upd} perm={perm} />
                </div>
              )}

              <div className={classes.inputsearchcon}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <select
                    onChange={handleRecordsChange}
                    value={numberOfRecords}
                    className={classes.selectfotnu}
                  >
                    {["10", "20", "50", "100"].map((value) => (
                      <option
                        key={value}
                        style={{ fontSize: "11px" }}
                        value={value}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                <div></div>
              </div>
              <div className={classes.tableContai}>
                {isLoading || isFetching ? (
                  <Loader />
                ) : (
                  <Table
                    rowData={dashboardList?.data?.data || []}
                    page={page}
                    numberOfRecords={numberOfRecords}
                    perm={perm}
                  />
                )}
              </div>
              <div className={classes.paginCont}>
                <p className={classes.pagitext}>
                  {" "}
                  Showing {dashboardList?.data?.from} to{" "}
                  {dashboardList?.data?.to} of {dashboardList?.data?.total}{" "}
                  entries
                </p>
                <PaginationComponent
                  pageChangeHandler={handleChange}
                  pageState={page}
                  totalPageNo={totalPage}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <AddModal addHandle={addHandle} />
      </Backdrop>
    </div>
  );
};

export default ExcelDisbursment;
