import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { style } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import MultiSelectInput from "../../Common/MultInputSelect/MultiInputSelect";
const AddModal = ({ addHandle, defaultValue, val }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,

    watch,
    control,
    setValue,
  } = useForm();
  const selectedDealerId = watch("dealer_id");
  console.log("selectedDealerId", selectedDealerId);
  // useEffect(() => {
  //   if (defaultValue) {
  //     setValue("language", defaultValue.department);
  //   }
  // }, [defaultValue, setValue]);

  console.log("defaultValue", defaultValue);
  const onSubmit = async (data) => {
    let payload;
    payload = {
      executive_id: val?.id,
      dealer_id: data?.dealer_id, // Extract only the IDs from the selected values
      //  dealer_ids: data.dealer_id.map((item) => item.id),
    };
    console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url: defaultValue
      ? `admin/get-dealers-by-lsp-executive/${val.id}`
      : "admin/store-lsp-executive-dealer-grouping",
    method: defaultValue ? "put" : "post",
    invalidateKey: "langList",
    successCallback: () => {
      reset();
      addHandle();
    },
  });
  const [{ data: depatmentList }] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("admin/dealer-screener-lsp").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  // console.log("depatmentList", depatmentList);
  return (
    <form
      style={{ width: "750px" }}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {defaultValue ? "Edit Dealers" : "Add Dealers"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div className={styles.modalform}>
          <lebel
            style={{
              marginBottom: "0.5rem",
              color: "#494646",
              fontSize: "13px",
            }}
          >
            Dealers
          </lebel>

          <div style={{ minHeight: "35px" }}>
            <Controller
              name={`dealer_id`}
              control={control}
              style={{ width: "180px" }}
              rules={{ required: "This Field is Required." }}
              defaultValue={[]}
              render={({ field }) => (
                <MultiSelectInput
                  style={{ width: "180px" }}
                  options={
                    depatmentList?.data?.map((item) => ({
                      name: item?.name,
                      id: item?.id,
                    })) || []
                  }
                  getOptionLabel={(val) => val?.name}
                  getOptionSelected={(option, value) =>
                    option?.id === value?.id
                  }
                  // onChange={(content, value) => {
                  //   // console.log({ content });
                  //   field.onChange(value);
                  // }}
                  // value={field.value}
                  onChange={(content, value) => {
                    const selectedIds = value?.map((item) => item?.id);
                    field.onChange(selectedIds);
                  }}
                  value={field.value.map((id) => ({
                    id,
                    name:
                      depatmentList?.data?.find((item) => item?.id === id)
                        ?.name || "",
                  }))}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>{`${option.name}`}</li>
                  )}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddModal;
