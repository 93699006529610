import React, { useState } from "react";
import styles from "./Table.module.css";

import { MdDelete } from "react-icons/md";

import CustomConfirmBox from "../../Common/Custom Confirm/CustomConfirm";
import useCustomMutation from "../../CustomHook/useCustomMutation";
const Table = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const updateDelMutation = useCustomMutation({
    url: `admin/delete-lsp-executive-dealer-grouping/${selectedItem?.id}`,
    method: "get",
    invalidateKey: "langList",

    successCallback: () => {},
  });
  const deleteHandler = (data) => {
    setSelectedItem(data);
    handleClickOpen();
    console.log(data);
  };

  const deleteConfirmHandler = () => {
    console.log("selectedData", selectedItem);
    updateDelMutation.mutate(selectedItem);
    handleClose();
  };
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      {open && (
        <CustomConfirmBox
          data={selectedItem}
          onCancel={handleClose}
          onConfirm={deleteConfirmHandler}
          open={open}
        />
      )}
      <div className={styles.tabwrap}>
        {" "}
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                background: "linear-gradient(to bottom, #12468a, #235392)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th>#</th>
                <th
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Dealer
                </th>

                <th> Action</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{row.name}</td>

                  <td>
                    {" "}
                    {perm?.includes("delete") && (
                      <button
                        onClick={() => deleteHandler(row)}
                        type="button"
                        className={styles.dltbtn}
                        // onClick={() => handleEditRow(row)}
                      >
                        <MdDelete
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
      </div>
    </div>
  );
};

export default Table;
