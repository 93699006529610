import React, { useState } from "react";
import styles from "./Table.module.css";
import { FaSort } from "react-icons/fa";
import {
  MdDelete,
  MdOutlineAddCircleOutline,
  MdPlaylistAddCheckCircle,
} from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import { useNavigate } from "react-router-dom";
import AddCharges from "./AddCharges";
import ViewCharges from "./ViewCharges";
const OEMTable = ({ rowData, page, numberOfRecords, perm, type }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [char, setChar] = useState(false);
  const addCharHandle = () => {
    setChar((val) => !val);
  };
  const handleCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setChar((val) => !val);
  };
  const [viewChar, setViewChar] = useState(false);
  const ViewCharHandle = () => {
    setViewChar((val) => !val);
  };
  const handleViewCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setViewChar((val) => !val);
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const navigate = useNavigate();
  const handleEditRow = (val) => {
    navigate("/add-product", {
      state: { val },
    });
  };
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      <div className={styles.tabwrap}>
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                background: "linear-gradient(to bottom, #12468a, #235392)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th style={{ minWidth: "40px" }}>#</th>

                <th style={{ minWidth: "120px" }}>Product Type</th>
                <th style={{ minWidth: "120px" }}>Model Name</th>
                {/* <th style={{ minWidth: "120px" }}>Model No.</th> */}
                <th style={{ minWidth: "120px" }}>Battery Type</th>

                {/* <th style={{ minWidth: "120px" }}>Ex-showroom Price</th> */}
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  {/* <td>{row.dealer_name}</td> */}
                  <td>{row.product_type}</td>
                  <td>{row.name}</td>
                  {/* <td>{row.model_unq}</td> */}
                  <td>{row.battery_type}</td>

                  {/* <td>{row.ex_showroom_price}</td> */}

                  {type !== "OEM" && (
                    <td>
                      {" "}
                      {perm?.includes("update") && (
                        <button
                          title="Edit"
                          type="button"
                          onClick={() => handleEditRow(row)}
                          className={styles.editbtn}
                        >
                          <AiFillEdit
                            size={12}
                            style={{ position: "relative", top: "1px" }}
                          />
                        </button>
                      )}
                      {perm?.includes("update") && (
                        <button
                          title="Product Charges"
                          type="button"
                          style={{
                            background: "orange",
                          }}
                          onClick={() => handleCharEditRow(row)}
                          className={styles.editbtn}
                        >
                          <MdOutlineAddCircleOutline
                            size={12}
                            style={{
                              position: "relative",
                              top: "1px",
                            }}
                          />
                        </button>
                      )}
                      <button
                        type="button"
                        title="View Product Charges"
                        style={{
                          background: "#FFD700",
                        }}
                        onClick={() => handleViewCharEditRow(row)}
                        className={styles.editbtn}
                      >
                        <MdPlaylistAddCheckCircle
                          size={12}
                          style={{
                            position: "relative",
                            top: "1px",
                          }}
                        />
                      </button>
                      {/* {perm?.includes("delete") && (
                        <button
                          title="Delete"
                          type="button"
                          className={styles.dltbtn}
                        >
                          <MdDelete
                            size={12}
                            style={{ position: "relative", top: "1px" }}
                          />
                        </button>
                      )} */}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AddModal addHandle={addHandle} defaultValue={rowToEdit || []} />
        </Backdrop>
        {char && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={char}
            onClick={addCharHandle}
          >
            <AddCharges
              addHandle={addCharHandle}
              defaultValue={rowToEdit || []}
            />
          </Backdrop>
        )}
        {viewChar && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={viewChar}
            onClick={ViewCharHandle}
          >
            <ViewCharges
              addHandle={ViewCharHandle}
              defaultValue={rowToEdit || []}
            />
          </Backdrop>
        )}
      </div>
    </div>
  );
};

export default OEMTable;
