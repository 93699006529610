import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
const AddModal = ({ addHandle, defaultValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    setValue,
  } = useForm();
  const password = watch("password");
  const [err, setErr] = useState({});
  useEffect(() => {
    if (defaultValue) {
      setValue("name", defaultValue?.name);
      setValue("mobile", defaultValue.mobile);
      setValue("email", defaultValue.email);
      setValue("department", defaultValue?.department_id);
      setValue("user_type", defaultValue.user_type_id);
    }
  }, [defaultValue, setValue]);
  const [{ data: depatmentList }, { data: userTypeList }] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("get-department-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["userType"],
        queryFn: () =>
          api.get("get-user-type-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  const onSubmit = async (data) => {
    let payload;
    if (defaultValue) {
      payload = {
        name: data?.name,
        mobile: data.mobile,
        email: data.email,
        department: data.department,
        user_type: data.user_type,
      };
    } else {
      payload = {
        name: data?.name,
        mobile: data.mobile,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
        department: data.department,
        user_type: data.user_type,
      };
    }
    // console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };
  // const updateVpnMutation = useCustomMutation({
  //   url: defaultValue ? `update-user/${defaultValue.id}` : "admin/register",
  //   method: defaultValue ? "put" : "post",
  //   invalidateKey: "userlist",
  //   successCallback: () => {
  //     reset();
  //     // addHandle();
  //   },
  // });
  const updateVpnMutation = useCustomMutation({
    url: defaultValue
      ? `admin/update-user/${defaultValue.id}`
      : "admin/register",
    method: defaultValue ? "put" : "post",
    invalidateKey: "userlist",
    successCallback: () => {
      reset();
      addHandle();
    },
    setErr: setErr,
  });
  console.log("Err", err);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      style={{ width: "520px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {defaultValue ? "Edit User" : "Add User"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Name
            </lebel>
            <input
              placeholder="Enter Name"
              {...register("name", { required: true })}
              type="text"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.name && (
              <span className={styles.error}>This Field is Required.</span>
            )}
            {/* <span className={styles.error}>
              {err?.name ? err?.name[0] : ""}
            </span> */}
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Mobile
            </lebel>
            <input
              placeholder="Enter Mobile"
              {...register("mobile", {
                required: true,
                minLength: {
                  value: 10,
                  message: "10 digits Required",
                },
              })}
              type="number"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.mobile && (
              <span className={styles.error}>
                {errors.mobile.message || "This Field is Misiing"}
              </span>
            )}
            {/* <span className={styles.error}>
              {err.mobile ? err.mobile[0] : ""}
            </span> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Email
            </lebel>
            <input
              placeholder="Enter Email"
              {...register("email", {
                required: "This field is required.",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]{1,}$/,
                  message: "Enter a valid email address.",
                },
              })}
              onChange={(e) => {
                const lowercaseValue = e.target.value.toLowerCase();
                e.target.value = lowercaseValue;
              }}
              type="email"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {/* {errors.email && (
              <span className={styles.error}>
                {err.email ? err.email[0] : "This Field is Missing."}
              </span>
            )} */}
            {errors.email && (
              <span className={styles.error}>{errors.email.message}</span>
            )}
            {/* <span className={styles.error}>
              {err.email ? err.email[0] : ""}
            </span> */}
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Department
            </lebel>
            <select
              style={{ width: "235px" }}
              {...register("department", { required: true })}
              className={styles.mdselecttt}
            >
              <option value="" className={styles.optionnsel}>
                --Select Department Name--
              </option>
              {depatmentList?.data?.map((val) => (
                <option key={val.id} value={val.id}>
                  {val.department}
                </option>
              ))}
            </select>

            {errors.department && (
              <span className={styles.error}>This Field is Required.</span>
            )}
          </div>
        </div>
        {!defaultValue && (
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div className={styles.modalform}>
              <lebel
                style={{
                  marginBottom: "3px",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Password
              </lebel>
              <input
                placeholder="Enter Password"
                {...register("password", {
                  required: true,
                  minLength: {
                    value: 8,
                    message: "Too Short",
                  },
                })}
                type="password"
                style={{ width: "225px" }}
                className={styles.mdlinput}
              />
              {errors.password && (
                <span className={styles.error}>
                  {errors.password.message || "This Field is Misiing"}
                </span>
              )}
            </div>
            <div className={styles.modalform}>
              <lebel
                style={{
                  marginBottom: "3px",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Confirm Password
              </lebel>
              <input
                placeholder="Confirm Password"
                {...register("password_confirmation", {
                  required: true,
                  minLength: {
                    value: 8,
                    message: "Too Short",
                  },
                  validate: (value) =>
                    value === password || "Passwords do not match",
                })}
                type="password"
                style={{ width: "225px" }}
                className={styles.mdlinput}
              />
              {/* {errors.password && (
                <span className={styles.error}>
                  {errors.password.message || "This Field is Misiing"}
                </span>
              )} */}
              {errors.password_confirmation && (
                <span className={styles.error}>
                  {errors.password_confirmation.message ||
                    "This Field is Missing"}
                </span>
              )}
            </div>
          </div>
        )}
        <div style={{ display: "flex", gap: "10px" }}>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              User Type
            </lebel>
            <select
              style={{ width: "235px" }}
              {...register("user_type", { required: true })}
              className={styles.mdselecttt}
            >
              <option value="" className={styles.optionnsel}>
                --Select User Type--
              </option>
              {userTypeList?.data?.map((val) => (
                <option key={val.id} value={val.id}>
                  {val.user_type}
                </option>
              ))}
            </select>
            {/* <input
              style={{ width: "225px" }}
              {...register("user_type", { required: true })}
              type="text"
              className={styles.mdlinput}
            /> */}
            {errors.user_type && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
        </div>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddModal;
