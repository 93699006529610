import React, { useContext, useEffect } from "react";
import styles from "./BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop } from "@mui/material";
import ViewImg from "./ViewImg";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
const DealerForm = ({ defaultValue }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [existingData, setExistingData] = useState(null);
  const [err, setErr] = useState({});
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  const { token, baseURL } = useContext(AuthContext);
  const [perm, setPerm] = useState([]);

  console.log("defaultValue.id", defaultValue.id);
  const fetchData = async () => {
    const response = await api.get(`admin/edit-oem-details/${defaultValue.id}`);
    return response.data;
  };
  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {
      setExistingData(data?.data);
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  console.log("existingData", existingData);
  const companyType = watch("company_type");
  console.log("companyType", companyType);
  useEffect(() => {
    if (existingData) {
      setValue("name", existingData?.manufacturer);
      setValue("date_of_incorporation", existingData?.date_of_incorporation);
      setValue("mobile", existingData?.mobile);
      setValue(
        "alternate_mobile_number",
        existingData?.alternate_mobile_number
      );
      setValue("email", existingData?.email);
      setValue("pan_number", existingData?.pan_number);

      setValue("landmark", existingData?.landmark);
      setValue("city", existingData?.city);
      setValue("pin", existingData?.pin);
      setValue("address_line_1", existingData?.address_line_1);
      setValue("address_line_2", existingData?.address_line_2);
      setValue("company_type", existingData?.company_type);
    }
  }, [existingData, setValue]);

  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("date_of_incorporation", data.date_of_incorporation);
    formData.append("mobile", data.mobile);
    formData.append("alternate_mobile_number", data.alternate_mobile_number);
    formData.append("email", data.email);
    formData.append("pan_number", data.pan_number);

    formData.append("landmark", data.landmark);
    formData.append("city", data.city);
    formData.append("pin", data.pin);
    formData.append("address_line_1", data.address_line_1);
    formData.append("address_line_2", data.address_line_2);

    formData.append("company_type", data.company_type);
    if (defaultValue && defaultValue.id) {
      formData.append("id", defaultValue.id);
    }

    const fileFields = [
      "bank_statement",

      "gst_certificate",
      "cheque_1",
      "cheque_2",
      "cheque_3",
      "cheque_4",
      "rent_agreement",
      "oem_finayo_agreement",

      "office_image",

      "itr_file",
      "pan_doc",

      "balance_sheet",

      "utility_bill",

      "partnership_deed",

      "moa",
      "aoa",
      "certificate_of_incorporation",
    ];

    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }

    try {
      const apiEndpoint = defaultValue
        ? `${baseURL}admin/update-oem-details`
        : `${baseURL}admin/update-oem-details`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      navigate("/oem-list");
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
      const { message, errors } = error.response.data;
      console.log("message", message);
      setErr(message);
      showToast(message, "error");
    }
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };

  useEffect(() => {
    if (existingData) {
      const fileFields = [
        "gst_certificate",
        // "cheque_1",
        // "cheque_2",
        // "cheque_3",
        // "cheque_4",
        "oem_finayo_agreement",

        "office_image",

        "utility_bill",

        "pan_doc",
      ];

      fileFields.forEach((field) => {
        if (!existingData[field]) {
          register(field, { required: true });
        } else {
          register(field);
        }
      });
    }
  }, [register, existingData]);
  console.log("companyType", companyType);
  useEffect(() => {
    if (companyType === "PARTNERSHIP") {
      if (existingData?.partnership_deed) {
        register("partnership_deed");
      } else {
        register("partnership_deed", { required: true });
      }
    } else {
      register("partnership_deed");
    }
  }, [companyType, register, existingData]);

  useEffect(() => {
    if (companyType === "PRIVATE LIMITED") {
      if (existingData?.balance_sheet) {
        register("balance_sheet");
      } else {
        register("balance_sheet");
      }
    } else {
      register("balance_sheet");
    }
    if (companyType === "PRIVATE LIMITED") {
      if (existingData?.moa) {
        register("moa");
      } else {
        register("moa", { required: true });
      }
    } else {
      register("moa");
    }
    if (companyType === "PRIVATE LIMITED") {
      if (existingData?.aoa) {
        register("aoa");
      } else {
        register("aoa", { required: true });
      }
    } else {
      register("aoa");
    }
    if (companyType === "PRIVATE LIMITED") {
      if (existingData?.certificate_of_incorporation) {
        register("certificate_of_incorporation");
      } else {
        register("certificate_of_incorporation", { required: true });
      }
    } else {
      register("certificate_of_incorporation");
    }
  }, [companyType, register, existingData]);
  return (
    <div style={{ marginBottom: "15px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "10px" }}>
        <div className={styles.totalcheckconnn}>
          {/* <div className={styles.searchformasc}> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Company Type<span className={styles.requ}>*</span>
            </label>
            <select
              style={{ height: "26px" }}
              className={styles.inputstst}
              {...register("company_type", {
                required: true,
              })}
            >
              <option value="">--Select Company Type--</option>
              <option value="PROPRIETORSHIP">PROPRIETORSHIP</option>
              <option value="PARTNERSHIP">PARTNERSHIP</option>
              <option value="PRIVATE LIMITED">PRIVATE LIMITED</option>
            </select>
            {errors.company_type && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Name<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Name"
              type="text"
              className={styles.inputstst}
              {...register("name", { required: true })}
            />
            {errors.name && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Date Of Incorporation<span className={styles.requ}>*</span>
              </label>
              <input
                type="date"
                className={styles.inputstst}
                {...register("date_of_incorporation", { required: true })}
              />
              {errors.date_of_incorporation && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Mobile Number<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Mobile Number"
              type="number"
              className={styles.inputstst}
              {...register("mobile", {
                required: true,
                minLength: {
                  value: 10,
                  message: "Please Enter 10 digits.",
                },
              })}
            />
            {errors.mobile && (
              <span className={styles.error}>
                {errors.mobile.message || "This Field is Missing."}
              </span>
            )}
            <span className={styles.error}>{err.mobile ? err.mobile : ""}</span>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Alternate Mobile Number
            </label>
            <input
              placeholder="Enter Alternate Mobile Number"
              type="number"
              className={styles.inputstst}
              {...register("alternate_mobile_number", {
                minLength: {
                  value: 10,
                  message: "Please Enter 10 digits.",
                },
              })}
            />

            {errors.alternate_mobile_number && (
              <span className={styles.error}>
                {errors.alternate_mobile_number.message ||
                  "This Field is Required."}
              </span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Email<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Email"
              type="email"
              className={styles.inputstst}
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
            <span className={styles.error}>{err.email ? err.email : ""}</span>
          </div>
        </div>
        <div className={styles.totalcheckconnn} style={{ marginTop: "15px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Address Line 1<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Address Line 1"
              type="text"
              className={styles.inputstst}
              {...register("address_line_1", { required: true })}
            />
            {errors.address_line_1 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Address Line 2<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Address Line 2"
              type="text"
              className={styles.inputstst}
              {...register("address_line_2", { required: true })}
            />
            {errors.address_line_2 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              City<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter City"
              type="text"
              className={styles.inputstst}
              {...register("city", { required: true })}
            />
            {errors.city && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Pincode<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter pincode"
              type="number"
              className={styles.inputstst}
              {...register("pin", {
                required: true,
                pattern: {
                  value: /^\d{6}$/,
                  message: "Please Enter Valid pincode",
                },
              })}
            />
            {errors.pin && (
              <span className={styles.error}>
                {errors.pin.message || "This Field is Missing."}
              </span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Landmark
            </label>
            <input
              placeholder="Enter Landmark"
              type="text"
              className={styles.inputstst}
              {...register("landmark")}
            />
            {errors.landmark && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              PAN No.<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter PAN Number"
              type="text"
              className={styles.inputstst}
              onInput={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              {...register("pan_number", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9]{10}$/,
                  message: "Please Enter Valid PAN Number",
                },
              })}
            />
            {errors.pan_number && (
              <span className={styles.error}>
                {errors.pan_number.message || "This Field is Missing."}
              </span>
            )}

            <span className={styles.error}>
              {err.pan_number ? err.pan_number : ""}
            </span>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Upload PAN <span className={styles.requ}>*</span>
            </label>
            <input
              className={styles.uploadbx}
              {...register("pan_doc")}
              type="file"
            />

            {errors.pan_doc && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              OEM Finayo Agreement<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("oem_finayo_agreement")}
              accept="application/pdf"
            />

            {errors.oem_finayo_agreement && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Bank Statement
            </label>
            <input
              className={styles.uploadbx}
              {...register("bank_statement")}
              type="file"
              accept="application/pdf"
            />

            {errors.bank_statement && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 1
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("cheque_1")}
              accept="image/jpeg, image/png,image/jpg"
            />
            {errors.cheque_1 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 2
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("cheque_2")}
              accept="image/jpeg, image/png,image/jpg"
            />
            {errors.cheque_2 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 3
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("cheque_3")}
              accept="image/jpeg, image/png,image/jpg"
            />
            {errors.cheque_3 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 4
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("cheque_4")}
              accept="image/jpeg, image/png,image/jpg"
            />
            {errors.cheque_4 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              GST Certificate<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("gst_certificate")}
              accept="application/pdf"
            />
            {errors.gst_certificate && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Rent Agreement
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("rent_agreement")}
              accept="application/pdf"
            />
            {errors.rent_agreement && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Office Premise Image<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("office_image")}
              accept="image/jpeg, image/png,image/jpg"
              //  onChange={handleChange("office_image")}
            />
            {errors.office_image && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              ITR File
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("itr_file")}
              accept="application/pdf"
            />
            {errors.itr_file && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Utility Bill<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("utility_bill")}
            />
            {errors.utility_bill && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          {companyType === "PARTNERSHIP" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Partnership Deed<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("partnership_deed")}
              />
              {errors.partnership_deed && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}

          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Balance Sheet
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("balance_sheet")}
                accept="application/pdf"
              />
              {errors.balance_sheet && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                MOA<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("moa")}
                // onChange={handleFileChange}
                accept="application/pdf"
                //   onChange={handleChange("itr_file")}
              />
              {errors.moa && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                AOA<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("aoa")}
                accept="application/pdf"
              />
              {errors.aoa && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Certificate Of Incorporation
                <span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("certificate_of_incorporation")}
                // onChange={handleFileChange}
                accept="application/pdf"
                //   onChange={handleChange("itr_file")}
              />
              {errors.certificate_of_incorporation && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
        </div>

        <div className={styles.buttonasc} style={{ marginTop: "15px" }}>
          <button type="submit" className={styles.Subbtn}>
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default DealerForm;
