import axios from "axios";
const api = axios.create({
  baseURL: "http://13.200.65.87/api/",
  //  baseURL: "https://insta.finayo.tech/api/",
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("ACCESS_TOKEN");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      localStorage.removeItem("ACCESS_TOKEN");
      localStorage.removeItem("borrowerListFilters");
      localStorage.removeItem("borrowerListB2CFilters");
      localStorage.removeItem("borrowerListB2BFilters");
      localStorage.removeItem("DealerListFilters");
      localStorage.removeItem("ProductListFilters");
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (
        error.response.status === 401 ||
        error.response.data.message === "Unauthenticated."
      ) {
        localStorage.removeItem("ACCESS_TOKEN");
        localStorage.removeItem("borrowerListFilters");
        localStorage.removeItem("borrowerListB2CFilters");
        localStorage.removeItem("borrowerListB2BFilters");
        localStorage.removeItem("DealerListFilters");
        localStorage.removeItem("ProductListFilters");
      }
    } else if (error.request) {
      console.log("Error Request:", error.request);
    } else {
      console.log("Error Message:", error.message);
    }

    return Promise.reject(error);
  }
);

export default api;
