// import React, { useContext, useEffect } from "react";
// import styles from "./BorrowerForm.module.css";
// import { Controller, useForm } from "react-hook-form";
// import { Document, Page } from "react-pdf";
// import { useState } from "react";
// import { Dialog } from "@mui/material";
// import axios from "axios";
// import { useToast } from "../../../context/ToastProvder";
// import { Backdrop } from "@mui/material";
// // import ViewImg from "./ViewImg";
// import { useQuery } from "@tanstack/react-query";
// import api from "../../Common/api";
// import { useNavigate } from "react-router-dom";
// import { AuthContext } from "../../../context/AuthContext";
// const ViewDet = ({ defaultValue }) => {
//   const [add, setAdd] = useState(false);
//   const { token, baseURL, baseDoc } = useContext(AuthContext);
//   const [rowToEdit, setRowToEdit] = useState(null);
//   const [existingData, setExistingData] = useState(null);
//   const [err, setErr] = useState({});
//   const [modalFocused, setModalFocused] = useState(true);

//   const handleFocus = () => setModalFocused(true);
//   const handleBlur = () => setModalFocused(false);
//   const navigate = useNavigate();
//   const {
//     register,
//     handleSubmit,
//     setValue,
//     watch,
//     formState: { errors },
//   } = useForm();
//   const { showToast } = useToast();
//   const [open, setOpen] = React.useState(false);
//   // const disableShortcuts = (e) => {
//   //   const blockedKeys = ["PrintScreen", "PrtSc", "Alt", "Ctrl", "Shift"];
//   //   if (blockedKeys.includes(e.key)) {
//   //     e.preventDefault();
//   //   }
//   // };

//   // useEffect(() => {
//   //   document.addEventListener("contextmenu", disableRightClick);
//   //   document.addEventListener("keydown", disableShortcuts);

//   //   return () => {
//   //     document.removeEventListener("contextmenu", disableRightClick);
//   //     document.removeEventListener("keydown", disableShortcuts);
//   //   };
//   // }, []);
//   // useEffect(() => {
//   //   const handleKeyUp = (e) => {
//   //     if (e.key === "PrintScreen") {
//   //       navigator.clipboard.writeText("");
//   //       alert("Screenshots disabled!");
//   //     }
//   //   };

//   //   const handleKeyDown = (e) => {
//   //     if (e.ctrlKey && e.key === "p") {
//   //       alert("This section is not allowed to print or export to PDF");
//   //       e.preventDefault();
//   //       e.stopImmediatePropagation();
//   //     }
//   //   };

//   //   document.addEventListener("keyup", handleKeyUp);
//   //   document.addEventListener("keydown", handleKeyDown);

//   //   return () => {
//   //     document.removeEventListener("keyup", handleKeyUp);
//   //     document.removeEventListener("keydown", handleKeyDown);
//   //   };
//   // }, []);
//   // const handleViewPdf = (pdfUrl) => {
//   //   if (pdfUrl) {
//   //     window.open(pdfUrl, "_blank");
//   //   }
//   // };
//   const [pdfUrl, setPdfUrl] = React.useState("");
//   const disableRightClick = (e) => {
//     e.preventDefault();
//   };

//   // useEffect(() => {
//   //   document.addEventListener("contextmenu", disableRightClick);
//   //   return () => {
//   //     document.removeEventListener("contextmenu", disableRightClick);
//   //   };
//   // }, []);
//   const handleViewPdf = (url) => {
//     const fullUrl = `${baseDoc}${url}`;
//     setPdfUrl(fullUrl);
//     setOpen(true);
//   };

//   // const handleViewPdf = (pdfUrl) => {
//   //   if (pdfUrl) {
//   //     const fullUrl = `${baseDoc}${pdfUrl}`;
//   //     window.open(fullUrl, "_blank");
//   //   }
//   // };
//   // const fetchData = async () => {
//   //   const response = await api.get(`edit-dealer/${defaultValue.id}`);
//   //   return response.data;
//   // };
//   // const DetailsResponse = useQuery({
//   //   queryKey: ["bankDetailsList"],
//   //   queryFn: () => fetchData(),
//   //   onSuccess: (data) => {
//   //     setExistingData(data?.data);
//   //   },
//   // });
//   // const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
//   // console.log("existingData", existingData);

//   const addHandle = () => {
//     setAdd((val) => !val);
//   };
//   const handleEditRow = (rowIndex) => {
//     setRowToEdit(rowIndex);
//     setAdd((val) => !val);
//   };
//   const overlayStyle = {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     // backgroundColor: "rgba(0, 0, 0, 0.5)",
//     pointerEvents: "none",
//   };
//   return (
//     <div style={{ marginBottom: "15px" }}>
//       {/* <Backdrop
//         sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
//         open={add}
//         onClick={addHandle}
//       >
//         <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
//       </Backdrop> */}
//       <div>
//         <div className={styles.searchformasc}>
//           <div className={styles.fourinputasc}>
//             <div className={styles.searorinp}>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 Name{" "}
//               </label>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                 }}
//               >
//                 {defaultValue?.name}
//               </label>
//             </div>
//             <div className={styles.searorinp}>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 Company Type
//               </label>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                 }}
//               >
//                 {defaultValue?.company_type}
//               </label>
//             </div>
//             <div className={styles.searorinp}>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 Aadhar
//               </label>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                 }}
//               >
//                 {defaultValue?.aadhar}
//               </label>
//             </div>

//             <div className={styles.searorinp}>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 PAN
//               </label>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                 }}
//               >
//                 {defaultValue?.pan}
//               </label>
//             </div>
//           </div>
//           <div className={styles.fourinputasc}>
//             <div className={styles.searorinp}>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 Mobile
//               </label>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                 }}
//               >
//                 {defaultValue?.mobile}
//               </label>
//             </div>
//             <div className={styles.searorinp}>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 Alternative Number
//               </label>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                 }}
//               >
//                 {defaultValue?.alternative_number}
//               </label>
//             </div>
//             <div className={styles.searorinp}>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 Email
//               </label>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                 }}
//               >
//                 {defaultValue?.email}
//               </label>
//             </div>
//             <div className={styles.searorinp}>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 Certificate of Incorporation
//               </label>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 {defaultValue && defaultValue?.certificate_of_incorporation && (
//                   <button
//                     type="button"
//                     onClick={() =>
//                       handleViewPdf(defaultValue.certificate_of_incorporation)
//                     }
//                     className={styles.bbtnvofdeubv}
//                   >
//                     View
//                   </button>
//                 )}
//               </label>
//             </div>
//           </div>
//           <div className={styles.fourinputasc}>
//             <div className={styles.searorinp}>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 Address Proof Document
//               </label>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 {defaultValue && defaultValue?.address_proof_doc && (
//                   <button
//                     type="button"
//                     onClick={() =>
//                       handleViewPdf(defaultValue.address_proof_doc)
//                     }
//                     className={styles.bbtnvofdeubv}
//                   >
//                     View
//                   </button>
//                 )}
//               </label>
//             </div>
//             <div className={styles.searorinp}>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 Finayo LSP Agreement
//               </label>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 {defaultValue && defaultValue?.finayo_lsp_agreement && (
//                   <button
//                     type="button"
//                     onClick={() =>
//                       handleViewPdf(defaultValue.finayo_lsp_agreement)
//                     }
//                     className={styles.bbtnvofdeubv}
//                   >
//                     View
//                   </button>
//                 )}
//               </label>
//             </div>
//             <div className={styles.searorinp}>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 GST Certificate
//               </label>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 {defaultValue && defaultValue?.gst_certificate && (
//                   <button
//                     type="button"
//                     onClick={() => handleViewPdf(defaultValue.gst_certificate)}
//                     className={styles.bbtnvofdeubv}
//                   >
//                     View
//                   </button>
//                 )}
//               </label>
//             </div>
//             <div className={styles.searorinp}>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 MOA
//               </label>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 {defaultValue && defaultValue?.moa && (
//                   <button
//                     type="button"
//                     onClick={() => handleViewPdf(defaultValue.moa)}
//                     className={styles.bbtnvofdeubv}
//                   >
//                     View
//                   </button>
//                 )}
//               </label>
//             </div>
//           </div>

//           <div className={styles.twoinputasctss}>
//             <div className={styles.searorinp}>
//               <label
//                 className={styles.ptpfrtxt}
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 AOA
//               </label>
//               <label
//                 className={styles.ptpfrtxt}
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 {defaultValue && defaultValue?.aoa && (
//                   <button
//                     type="button"
//                     onClick={() => handleViewPdf(defaultValue.aoa)}
//                     className={styles.bbtnvofdeubv}
//                   >
//                     View
//                   </button>
//                 )}
//               </label>
//             </div>
//             <div className={styles.searorinp}>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 Lender LSP Agreement
//               </label>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 {defaultValue && defaultValue?.lender_lsp_agreement && (
//                   <button
//                     type="button"
//                     onClick={() =>
//                       handleViewPdf(defaultValue.lender_lsp_agreement)
//                     }
//                     className={styles.bbtnvofdeubv}
//                   >
//                     View
//                   </button>
//                 )}
//               </label>
//             </div>

//             {/* <div className={styles.searorinp}>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 Certificate Of Incorporation
//                 {existingData && existingData?.certificate_of_incorporation && (
//                   <button
//                     type="button"
//                     onClick={() =>
//                       handleViewPdf(existingData.certificate_of_incorporation)
//                     }
//                     className={styles.bbtnvofdeubv}
//                   >
//                     View
//                   </button>
//                 )}
//               </label>
//             </div>
//             <div className={styles.searorinp}>
//               <label
//                 style={{
//                   fontSize: "12px",
//                   color: "#000000",
//                   fontWeight: "bold",
//                 }}
//               >
//                 Factory Address Proof{" "}
//                 {existingData && existingData?.factory_address_proof && (
//                   <button
//                     type="button"
//                     onClick={() =>
//                       handleViewPdf(existingData.factory_address_proof)
//                     }
//                     className={styles.bbtnvofdeubv}
//                   >
//                     View
//                   </button>
//                 )}
//               </label>
//             </div> */}
//           </div>
//           <Dialog
//             open={open}
//             onClose={() => setOpen(false)}
//             fullWidth
//             maxWidth="lg"
//             onFocus={handleFocus}
//             onBlur={handleBlur}
//             BackdropProps={{
//               onClick: () => setOpen(false),
//             }}
//           >
//             <div
//               style={{
//                 position: "relative",
//                 width: "100%",
//                 height: "2000px",
//                 overflowY: "scroll",
//               }}
//             >
//               <button
//                 onClick={() => setOpen(false)}
//                 style={{
//                   position: "absolute",
//                   top: "10px",
//                   right: "10px",
//                   zIndex: 10,
//                   backgroundColor: "white",
//                   border: "none",
//                   cursor: "pointer",
//                   padding: "5px 10px",
//                   borderRadius: "5px",
//                   width: "300px",
//                 }}
//               >
//                 Close
//               </button>

//               <iframe
//                 src={pdfUrl}
//                 style={{
//                   width: "100%",
//                   height: "2000px",
//                   border: "none",
//                   pointerEvents: "none",
//                 }}
//               />

//               <div style={overlayStyle} />
//             </div>
//           </Dialog>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ViewDet;
import React, { useContext, useEffect } from "react";
import styles from "./BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { Document, Page } from "react-pdf";
import { useState } from "react";
import { Dialog } from "@mui/material";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop } from "@mui/material";
// import ViewImg from "./ViewImg";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import ViewImg from "./ViewImg";
import ZoomImg from "../EditGuarantorDocuments/ZoomImg";
const ViewDet = ({ defaultValue }) => {
  const [add, setAdd] = useState(false);
  const { token, baseURL, baseDoc } = useContext(AuthContext);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [bimg, setBimg] = useState(false);
  const [zoomImgUrl, setZoomImgUrl] = useState(null);
  const [existingData, setExistingData] = useState(null);
  const [err, setErr] = useState({});
  const [modalFocused, setModalFocused] = useState(true);

  const handleFocus = () => setModalFocused(true);
  const handleBlur = () => setModalFocused(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  const [open, setOpen] = React.useState(false);
  const fetchData = async () => {
    const response = await api.get(`admin/edit-oem-details/${defaultValue.id}`);
    return response.data;
  };
  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {
      setExistingData(data?.data);
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  console.log("existingData", existingData);
  const [pdfUrl, setPdfUrl] = React.useState("");
  const disableRightClick = (e) => {
    e.preventDefault();
  };
  const getFullUrl = (path) => (path ? `${baseDoc}${path}` : null);

  // useEffect(() => {
  //   document.addEventListener("contextmenu", disableRightClick);
  //   return () => {
  //     document.removeEventListener("contextmenu", disableRightClick);
  //   };
  // }, []);
  const handleViewPdf = (url) => {
    const fullUrl = `${baseDoc}${url}`;
    setPdfUrl(fullUrl);
    setOpen(true);
  };

  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // backgroundColor: "rgba(0, 0, 0, 0.5)",
    pointerEvents: "none",
  };
  const imgHandle = (url) => {
    setZoomImgUrl(url);
    setBimg((val) => !val);
  };
  console.log("rec url", zoomImgUrl);
  return (
    <div style={{ marginBottom: "15px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={bimg}
        onClick={imgHandle}
      >
        <ZoomImg
          addHandle={imgHandle}
          zoomImgUrl={zoomImgUrl}
          // borrowerId={borrowerId}
        />
      </Backdrop>
      <div>
        <div className={styles.totalcheckconnn} style={{ marginTop: "10px" }}>
          {/* <div className={styles.fourinputasc}> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Name
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {existingData?.manufacturer}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Company Type
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {existingData?.company_type}
            </label>
          </div>
          {existingData?.company_type === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Date Of Incorporation
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {existingData?.date_of_incorporation}
              </label>
            </div>
          )}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Mobile Number
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {existingData?.mobile}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Alternate Mobile Number
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {existingData?.alternate_mobile_number}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Email
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {existingData?.email}
            </label>

            <span className={styles.error}>{err.email ? err.email : ""}</span>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Address Line 1
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {existingData?.address_line_1}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Address Line 2
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {existingData?.address_line_2}
            </label>
          </div>{" "}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              City
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {existingData?.city}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Pincode
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {existingData?.pin}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Landmark
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {existingData?.landmark}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              PAN No.
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {existingData?.pan_number}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Uploaded PAN
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {existingData && existingData?.pan_doc && (
                <button
                  type="button"
                  // onClick={() => handleViewPdf(existingData.pan)}
                  onClick={() => imgHandle(getFullUrl(existingData.pan_doc))}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Bank Statement
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {existingData && existingData?.bank_statement && (
                <button
                  type="button"
                  // onClick={() => handleViewPdf(existingData.bank_statement)}
                  onClick={() =>
                    imgHandle(getFullUrl(existingData.bank_statement))
                  }
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          {/* <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Trade Certificate{" "}
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {existingData && existingData?.trade_certificate && (
                <button
                  type="button"
                  onClick={() => handleViewPdf(existingData.trade_certificate)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              GST Certificate{" "}
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {existingData && existingData?.gst_certificate && (
                <button
                  type="button"
                  onClick={() => handleViewPdf(existingData.gst_certificate)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 1
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {existingData && existingData?.cheque_1 && (
                <button
                  type="button"
                  // onClick={() => handleEditRow(existingData.security_cheque_1)}
                  onClick={() => imgHandle(getFullUrl(existingData.cheque_1))}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 2
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {existingData && existingData?.cheque_2 && (
                <button
                  type="button"
                  onClick={() => imgHandle(getFullUrl(existingData.cheque_2))}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 3
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {existingData && existingData?.cheque_3 && (
                <button
                  type="button"
                  onClick={() => imgHandle(getFullUrl(existingData.cheque_3))}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 4
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {existingData && existingData?.cheque_4 && (
                <button
                  type="button"
                  onClick={() => imgHandle(getFullUrl(existingData.cheque_4))}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Rent Agreement
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {existingData && existingData?.rent_agreement && (
                <button
                  type="button"
                  onClick={() =>
                    imgHandle(getFullUrl(existingData.rent_agreement))
                  }
                  // onClick={() => handleViewPdf(existingData.rent_agreement)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Office Premise Image
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {existingData && existingData?.office_image && (
                <button
                  type="button"
                  onClick={() =>
                    imgHandle(getFullUrl(existingData.office_image))
                  }
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          {/* <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer Image 2
              {existingData && existingData?.dealer_image_2 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.dealer_image_2)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div> */}
          {/* <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Showroom Image 2
              {existingData && existingData?.showroom_img_2 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.showroom_img_2)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Showroom Image 3
              {existingData && existingData?.showroom_img_3 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.showroom_img_3)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Showroom Image 4
              {existingData && existingData?.showroom_img_4 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.showroom_img_4)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              ITR File
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {existingData && existingData?.itr_file && (
                <button
                  type="button"
                  onClick={() => handleViewPdf(existingData.itr_file)}
                  // onClick={() => imgHandle(getFullUrl(existingData.itr_file))}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              OEM Finayo Agreement
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {existingData && existingData?.oem_finayo_agreement && (
                <button
                  type="button"
                  // onClick={() =>
                  //   imgHandle(getFullUrl(existingData.lsp_finayo_agreement))
                  // }
                  onClick={() =>
                    handleViewPdf(existingData.oem_finayo_agreement)
                  }
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          {/* <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Manufacturing License{" "}
              {existingData && existingData?.manufacturing_license && (
                <button
                  type="button"
                  onClick={() =>
                    handleViewPdf(existingData.manufacturing_license)
                  }
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              ICAT Certificate{" "}
              {existingData && existingData?.icat_certificate && (
                <button
                  type="button"
                  onClick={() => handleViewPdf(existingData.icat_certificate)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Utility Bill{" "}
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {existingData && existingData?.utility_bill && (
                <button
                  type="button"
                  // onClick={() =>
                  //   imgHandle(getFullUrl(existingData.utility_bill))
                  // }
                  onClick={() => handleViewPdf(existingData.utility_bill)}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
          {existingData?.company_type === "PARTNERSHIP" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Partnership Deed{" "}
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                {existingData && existingData?.partnership_deed && (
                  <button
                    type="button"
                    // onClick={() =>
                    //   imgHandle(getFullUrl(existingData.partnership_deed))
                    // }
                    onClick={() => handleViewPdf(existingData.partnership_deed)}
                    className={styles.bbtfnvogfde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          )}
          {/* {existingData?.company_type === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Balance Sheet
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                {existingData && existingData?.balance_sheet && (
                  <button
                    type="button"
                    // onClick={() =>
                    //   imgHandle(getFullUrl(existingData.balance_sheet))
                    // }
                    onClick={() => handleViewPdf(existingData.balance_sheet)}
                    className={styles.bbtfnvogfde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          )}{" "} */}
          {existingData?.company_type === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                MOA
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                {existingData && existingData?.moa && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(existingData.moa)}
                    // onClick={() => imgHandle(getFullUrl(existingData.moa))}
                    className={styles.bbtfnvogfde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          )}{" "}
          {existingData?.company_type === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                AOA
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                {existingData && existingData?.aoa && (
                  <button
                    type="button"
                    // onClick={() => imgHandle(getFullUrl(existingData.aoa))}
                    onClick={() => handleViewPdf(existingData.aoa)}
                    className={styles.bbtfnvogfde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          )}{" "}
          {existingData?.company_type === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Certificate Of Incorporation
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                {existingData && existingData?.certificate_of_incorporation && (
                  <button
                    type="button"
                    // onClick={() =>
                    //   imgHandle(
                    //     getFullUrl(existingData.certificate_of_incorporation)
                    //   )
                    // }
                    onClick={() =>
                      handleViewPdf(existingData.certificate_of_incorporation)
                    }
                    className={styles.bbtfnvogfde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          )}
          {/* {existingData?.company_type === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Factory Address Proof{" "}
                {existingData && existingData?.factory_address_proof && (
                  <button
                    type="button"
                    onClick={() =>
                      handleViewPdf(existingData.factory_address_proof)
                    }
                    className={styles.bbtfnvogfde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          )} */}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          <button
            onClick={() => setOpen(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 10,
              backgroundColor: "white",
              border: "none",
              cursor: "pointer",
              padding: "5px 10px",
              borderRadius: "5px",
              width: "300px",
            }}
          >
            Close
          </button>

          <iframe
            src={pdfUrl}
            style={{
              width: "100%",
              height: "2000px",
              border: "none",
              pointerEvents: "none",
            }}
          />

          <div style={overlayStyle} />
        </div>
      </Dialog>
    </div>
  );
};

export default ViewDet;
